import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import AsyncSelect from 'react-select/async';
const MyDropdown = ({ value, response }) => {
  const [selectedOption, setSelectedOption] = useState([1, 2]);
  const options = [
    { key: '1', value: 1, text: 'Option 1' },
    { key: '2', value: 2, text: 'Option 2' },
    { key: '3', value: 3, text: 'Option 3' },
  ];
  useEffect(() => {
    //if (response.massfrequency.attribute_ids) {
      // Convert comma-separated string to an array
      //const idsArray = response.massfrequency.attribute_ids.split(',').map(Number); // Split and convert to numbers
      //setSelectedOption(idsArray);
    //}
  }, []); // Trigger this effect whenever the attribute_ids change

  const handleDropDownChange = (event, data, attribID) => {
    setSelectedOption(data.value); // Update selected options on change
    console.log('Selected options:', data);
  };

  const loadOptions = (inputValue) => {
    //return fetch(`https://api.example.com/search?query=${inputValue}`)
    return fetch(`https://restcountries.com/v3.1/name/${inputValue}`)
      .then(response => response.json())
      .then(data => data.map(item => ({ label: item.name.common, value: item.id })));
  };

  return (
    <div style={{marginLeft: '250px'}}>
    <Dropdown
      value={selectedOption}  // Ensure this is an array for multiple selection
      fluid
      search
      multiple
      clearable
      selection
      onChange={(event, data) => handleDropDownChange(event, data, "11")}
      options={options}  // Ensure the options array matches the expected structure
    />

    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      placeholder="Search and select..."
    />

    </div>
    
  );
};

export default MyDropdown;
